@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #070312;
  --foreground: rgb(199 210 254 / 0.65);
  --font-good-times: "Good Times", sans-serif;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --background: #0a0a0a;
//     --foreground: #ededed;
//   }
// }

html {
  font-family: "SF Pro Display", sans-serif;
  color: rgb(199 210 254 / 65%);
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
}

body {
  color: var(--foreground);
  background: var(--background);
  color: rgb(199 210 254 / 65%);
  font-size: 16px;
  line-height: 1.5;
}

.logo-mozg {
  font-size: 26px;
  font-weight: 600;
  color: #b050ff;
  font-family: var(--font-good-times);
  letter-spacing: 6px;
}
.logo-mozg-white {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  font-family: var(--font-good-times);
  letter-spacing: 6px;
}

.logo-ii {
  font-size: 9.2px;
  font-weight: 400;
  color: #fff;
  font-family: var(--font-good-times);
  letter-spacing: 1px;
}

.text-regular-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #e5e7eb, #c7d2fe, #f9fafb, #a5b4fc, #e5e7eb);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-primary-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #8fe548 25%, #ffffff 50%, #8fe548 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-warning-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffcc00 25%, #ffffff 50%, #ffcc00 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-info-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #b050ff 25%, #ffffff 50%, #b050ff 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-regular-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #e5e7eb, #c7d2fe, #f9fafb, #a5b4fc, #e5e7eb);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-primary-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffffff 25%, #8fe548 25%, #ffffff 25%, #8fe548 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-warning-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffffff 25%, #ffcc00 25%, #ffffff 25%, #ffcc00 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-info-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffffff 25%, #b050ff 25%, #ffffff 25%, #b050ff 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.holi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 10%, /* Less bright white */ rgba(139, 69, 19, 0.4) 40%, /* Darker brown */ rgba(0, 0, 0, 0.8) 70%); /* Darker black */
  background-blend-mode: screen;
  mix-blend-mode: lighten;
  pointer-events: none; /* Ensure overlay doesn't block interaction */
  animation: shiftGradient 10s infinite alternate;
}

.holi-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 0, 0, 0.5) 10%, rgba(255, 165, 0, 0.5) 30%, rgba(0, 255, 0, 0.5) 50%, rgba(0, 0, 255, 0.5) 70%, rgba(75, 0, 130, 0.5) 90%);
  background-blend-mode: screen;
  mix-blend-mode: multiply;
}

/* Animation for vibrant color pulses (optional) */
@keyframes holiPulse {
  0%,
  100% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }
}

.holi-overlay-2 {
  animation: holiPulse 5s infinite ease-in-out;
}

/* Animation for gradient movement to maintain dynamism */
@keyframes shiftGradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.nebula-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 50%, rgba(58, 125, 170, 0.3), transparent), radial-gradient(circle at 70% 20%, rgba(255, 99, 71, 0.2), transparent),
    radial-gradient(circle at 90% 70%, rgba(128, 0, 128, 0.3), transparent);
  opacity: 0.5;
  animation: nebulaMove 30s infinite ease-in-out;
  z-index: 1;
}

/* Nebula movement animation */
@keyframes nebulaMove {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100px 100px;
  }
}

.nebula-bg-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 50%, rgba(30, 60, 90, 0.5), transparent 60%), radial-gradient(circle at 70% 20%, rgba(80, 0, 40, 0.4), transparent 60%),
    radial-gradient(circle at 90% 70%, rgba(50, 0, 80, 0.5), transparent 60%), rgba(0, 0, 0, 0.8); /* Stronger black overlay */
  opacity: 0.8;
  animation: hollywoodNebulaMove 60s infinite ease-in-out;
  z-index: 1;
  transform-origin: center;
}

/* Hollywood-style nebula movement animation */
@keyframes hollywoodNebulaMove {
  0% {
    transform: scale(1) translate(0px, 0px);
    opacity: 0.8;
  }
  25% {
    transform: scale(1.05) translate(-20px, -10px);
    opacity: 0.85;
  }
  50% {
    transform: scale(1.1) translate(-40px, -20px);
    opacity: 0.9;
  }
  75% {
    transform: scale(1.05) translate(-20px, -10px);
    opacity: 0.85;
  }
  100% {
    transform: scale(1) translate(0px, 0px);
    opacity: 0.8;
  }
}

.bg-card-dark-2 {
  background-color: rgb(31 41 55 / 0.4);
  border: 1px solid #1f2937;
  border-radius: 1rem;
}

$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

/* Define theme colors */
$theme-colors: (
  "primary": "#8fe548",
  "regular": "#a5b4fc",
  "info": "#b050ff",
  "danger": "#dc3545"
) !default;

/* Generate dynamic color classes */
@each $color, $value in $theme-colors {
  ul.custom-check-list-#{$color} {
    list-style-type: none; /* Remove default bullet */
    padding-left: 0; /* Remove padding */
  }

  ul.custom-check-list-#{$color} li {
    position: relative; /* Enable positioning for the pseudo-element */
    padding-left: 24px; /* Space for the icon */
  }

  ul.custom-check-list-#{$color} li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.2em;
    width: 18px;
    height: 18px;
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$value}'/></svg>")
    );
    background-size: contain;
    background-repeat: no-repeat;
  }

  ul.custom-check-list-soft-bg-#{$color} li::before {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$value}' fill-opacity='0.1'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$value}'/></svg>")
    );
  }
}

@each $size, $dimensions in (sm: 14px, md: 18px, lg: 22px, xl: 26px) {
  ul.custom-check-list-size-#{$size} li::before {
    width: $dimensions;
    height: $dimensions;
  }

  ul.custom-check-list-size-#{$size} li {
    padding-left: $dimensions + 16px;
  }
}

@keyframes flickerAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.9;
  }
}

.flicker {
  animation: flickerAnimation 2s ease-out 1 forwards;
}

@keyframes colorStain {
  0% {
    color: white; /* Start color */
  }
  25% {
    color: #8fe548; /* Red */
  }
  50% {
    color: white; /* Yellow */
  }
  75% {
    color: #8fe548; /* Green */
  }

  100% {
    color: white; /* Back to Start */
  }
}

.logo-stain {
  animation: colorStain 5s infinite alternate;
}
